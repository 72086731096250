import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Content from '../components/content';

const NotFoundPage = () => (
  <Fragment>
    <Helmet>
      <meta charSet="utf-8" />
    </Helmet>
    <Layout>
      <Content>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Content>
    </Layout>
  </Fragment>
);

export default NotFoundPage;
